import { graphql, Link, useStaticQuery } from 'gatsby';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import React, { FunctionComponent, ReactElement } from 'react';
import SEO from '~/components/Global/SEO';
import Image from '~/components/Image';
import Layout from '~/components/Layout';
import BlogPost from '../templates/post';

const NotFoundPage: FunctionComponent = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query NotFoundQuery {
      wp {
        options {
          globalOptions {
            fourOhFour {
              image {
                ...Image
              }
              heading
              content
              button {
                title
                url
              }
            }
          }
        }
      }
    }
  `);

  const {
    image,
    heading,
    content,
    button,
  } = data.wp.options.globalOptions.fourOhFour;

  return (
    <Layout bgcolour="bg-lgrey">
      <SEO title="404: Not found" />
      <main>
        <section className="py-12 text-center md:py-24 xl:py-48">
          <div className="container">
            <Image
              data={image}
              className="w-full max-w-2xl mx-auto mb-8 md:mb-20"
            />
            <h2
              dangerouslySetInnerHTML={{
                __html: heading,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
            <Link to={button?.url} className="btn">
              {button?.title}
            </Link>
          </div>
        </section>
      </main>
    </Layout>
  );
};

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    blog_post: BlogPost,
  },
});
